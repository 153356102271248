export const HOME_DISCLOSURES_STRINGS = {
  title: 'Anything important we need to tell future <span>renters about?</span>',
  subtitle: 'Select all legally required disclosures that you know about at <strong>{address}</strong>.',
  describe: 'Additional Information',
  permitted_bedrooms: '# of permitted bedrooms',
  'disclosures_documentation.title': "We've prepared your <span>disclosures form.</span>",
  'disclosures_documentation.subtitle':
    "As required by law or regulation, we'll include this in your future <span>Residents' lease.</span>",
  done_label: 'Next Section',
  'done_label.units': 'Next Unit',
  view_form: 'View Form',
  checkbox_label: 'I agree to electronically sign this\u00a0form.',
  known_radon_concentration: 'Are there known radon concentrations?',
  bed_bugs_treated: 'Was it treated?',
  building_bed_bugs_floors_where_it_occurred: 'Floors where it occurred',
};
